<template>
  <base-form-layout
    :title="`${action} ${$tc('product.name', 1)}`"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <ItemProductEditForm :item="itemProduct" />
    </v-form>
  </base-form-layout>
</template>

<script>
import ItemProductEditForm from './components/ItemProductEditForm'
import { mapActions } from 'vuex'
import { ITEM_PRODUCT } from '@/models/item.product'
import { FormMixin } from '@/mixins/form.mixin'
// import { DocumentMixin } from '@/mixins/document.mixin.js'
export default {
  components: {
    ItemProductEditForm
  },
  mixins: [FormMixin],
  props: {
    itemProductId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      itemProduct: ITEM_PRODUCT(),
      isLoading: false
    }
  },
  computed: {
    action() {
      return this.$t(this.itemProductId ? 'actions.edit' : 'actions.create')
    }
  },
  async created() {
    if (this.itemProductId) this.itemProduct = await this.fetchItemProduct()
    this.isLoading = false
  },
  methods: {
    async fetchItemProduct() {
      const { data } = await this.productFetch(this.itemProductId)
      return data
    },
    async onSubmit() {
      this.beforeSubmit = false
      this.isLoading = true
      try {
        const action = this.itemProductId ? 'update' : 'create'
        const fn = payload =>
          this.itemProductId
            ? this.productUpdate(payload)
            : this.productCreate(payload)

        await fn(this.itemProduct)
        // await this.createDocument(data.id, 'Item', 'Attachment')
        this.isLoading = false
        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: `Successfully ${action}d` }
        })
        this.$router.go(-1)
      } catch (error) {
        this.isLoading = false
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    },
    ...mapActions({
      productFetch: 'itemProduct/fetch',
      productCreate: 'itemProduct/create',
      productUpdate: 'itemProduct/update',
      addNotification: 'notification/add'
    })
  }
}
</script>

<template>
  <div>
    <h6 class="text-h6 mb-5">
      {{ $tc('product.name', 1) }} {{ $t('common.details') }}
    </h6>
    <base-selector
      v-model="item.attributes.name"
      :label="$t('common.name')"
      :items="instrumentTypes"
    />
    <v-row dense>
      <v-col>
        <base-field-input
          v-model="item.attributes.customAttributes.code"
          placeholder="ESCN44CESSAM"
          label="Code"
        />
      </v-col>

      <v-col>
        <base-field-input
          v-model="item.attributes.customAttributes.model"
          placeholder="522Ce 12 Fret-V-Class"
          :label="$t('product.model')"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <base-field-input
          v-model="item.attributes.customAttributes.style"
          placeholder="Grand Concert Cutaway"
          :label="$t('product.style')"
        />
      </v-col>

      <v-col>
        <base-field-input
          v-model="item.attributes.customAttributes.alias"
          placeholder="Cordoba"
          label="Alias"
        />
      </v-col>
    </v-row>

    <h6 class="text-h6 mb-5">
      {{ $t('common.owner') }} {{ $t('common.details') }}
    </h6>
    <v-row dense>
      <v-col>
        <base-field-input
          v-model="item.attributes.customAttributes.ownerFirstName"
          placeholder="John"
          :label="$t('user.firstName')"
      /></v-col>
      <v-col>
        <base-field-input
          v-model="item.attributes.customAttributes.ownerLastName"
          placeholder="Mayer"
          :label="$t('user.lastName')"/></v-col
    ></v-row>

    <h6 class="text-h6 mb-5">
      {{ $tc('common.other', 2) }} {{ $t('common.details') }}
    </h6>

    <base-textarea-input
      v-model="item.attributes.customAttributes.details"
      :placeholder="`${$tc('common.other', 1)} ${$t('common.comments')}`"
      rows="4"
      :label="$t('common.details')"
    ></base-textarea-input>
    <!-- <h6 class="text-h6 mb-5">{{ $tc('document.attachment', 2) }}</h6>
    <v-divider class="mb-8"></v-divider>
    <base-file-input /> -->
  </div>
</template>

<script>
import { INSTRUMENT_TYPES } from '@/common/terms'
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      instrumentTypes: INSTRUMENT_TYPES
    }
  }
}
</script>

import { ORGANIZATION_ID } from '@/common/config'
import store from '@/store/index'

export const ITEM_PRODUCT = (name = 'Guitar') => ({
  type: 'item',
  attributes: {
    name,
    orderId: '',
    facilityId: '',
    supplierId: '',
    clientId: store.state.auth.authUser.data.id,
    status: 'in_stock',
    organizationId: ORGANIZATION_ID(),
    category: 'product',
    description: '',
    quantity: 1,
    dimensions: { length: null, width: null, height: null, diameter: null },
    dimensionsUnit: 'mm',
    volume: null,
    volumeUnit: 'm³',
    customAttributes: {
      code: '',
      model: '',
      style: '',
      alias: '',
      ownerFirstName: '',
      ownerLastName: '',
      details: '',
      mainViewFile: null,
      frontViewFile: null,
      backViewFile: null
    },
    sellingPrice: 0,
    sellingPriceCurrency: '',
    costPrice: 0,
    costPriceCurrency: '',
    tax: '',
    systemNumbers: '',
    systemNumbersUnit: '',
    parentId: '',
    itemGroupId: null,
    weight: null,
    weightUnit: ''
  }
})
